import {GetGuildOwnershipResponse, GuildsApi, Token} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {useQuery} from "@tanstack/react-query";

async function fetchGuildOwnerships(): Promise<GetGuildOwnershipResponse> {
    return await new GuildsApi(getConfig()).getGuildOwnerships();
}

function useGetGuildOwnershipsQuery(fullToken: Token, enabled?: boolean) {
    // enabled is optional, used to disable the request being sent when we just need refetching on demand
    return {
        queryKey: [
            "guild-ownerships",
            fullToken?.id ?? "unauthenticated",
        ],
        queryFn: () => fetchGuildOwnerships(),
        staleTime: 30 * 60000,
        enabled: enabled ?? true,
    } as const;
}

export default function useGetGuildOwnerships(fullToken: Token, enabled: boolean = true) {
    return useQuery(useGetGuildOwnershipsQuery(fullToken, enabled));
}