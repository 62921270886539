import {ReactElement} from "react";
import {isDesktop} from "react-device-detect";
import useThemePreference from "@/hooks/useThemePreference";

interface Props {
    noOfCards: number;
}
function GoFrensMyNftsSkeleton(props: Props): ReactElement {
    const {isOnDarkMode} = useThemePreference();

    return (
        <div className="gofrens-my-nfts_own-nfts">
            {Array.from({length: props.noOfCards}, (_, i) => <div
                className="gofrens-my-nfts_own-nfts_card react-loading-skeleton"
                key={i}
                style={{
                    height: isDesktop ? "17rem" : "14.125rem",
                    backgroundColor: !isOnDarkMode && "rgb(235, 235, 235)",
                }}
            />)}
        </div>
    );
}

export default GoFrensMyNftsSkeleton;