/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Guild,
    GuildFromJSON,
    GuildFromJSONTyped,
    GuildToJSON,
    NftOwnership,
    NftOwnershipFromJSON,
    NftOwnershipFromJSONTyped,
    NftOwnershipToJSON,
} from './';

/**
 * User\'s guild badges and data of all the guilds .
 * @export
 * @interface GetGuildOwnershipResponse
 */
export interface GetGuildOwnershipResponse {
    /**
     * 
     * @type {Array<NftOwnership>}
     * @memberof GetGuildOwnershipResponse
     */
    badges: Array<NftOwnership>;
    /**
     * 
     * @type {Array<Guild>}
     * @memberof GetGuildOwnershipResponse
     */
    qualifiedGuilds: Array<Guild>;
    /**
     * 
     * @type {Array<Guild>}
     * @memberof GetGuildOwnershipResponse
     */
    otherGuilds: Array<Guild>;
}

export function GetGuildOwnershipResponseFromJSON(json: any): GetGuildOwnershipResponse {
    return GetGuildOwnershipResponseFromJSONTyped(json, false);
}

export function GetGuildOwnershipResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetGuildOwnershipResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'badges': ((json['badges'] as Array<any>).map(NftOwnershipFromJSON)),
        'qualifiedGuilds': ((json['qualifiedGuilds'] as Array<any>).map(GuildFromJSON)),
        'otherGuilds': ((json['otherGuilds'] as Array<any>).map(GuildFromJSON)),
    };
}

export function GetGuildOwnershipResponseToJSON(value?: GetGuildOwnershipResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'badges': ((value.badges as Array<any>).map(NftOwnershipToJSON)),
        'qualifiedGuilds': ((value.qualifiedGuilds as Array<any>).map(GuildToJSON)),
        'otherGuilds': ((value.otherGuilds as Array<any>).map(GuildToJSON)),
    };
}


