import {ReactElement, useState} from "react";
import {useNftInformationFromUri} from "@/hooks/useNftInformationFromUri";
import NftOwnershipCardModal from "@/components/modals/NftOwnershipCardModal";
import classNames from "classnames";
import {ExtendedNftOwnership} from "@/components/goFrens/GoFrensMyNfts";
import Tooltip from "../Tooltip";
import {ReactComponent as InfoCircle} from "../../svgs/info-circle.svg";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {useGetUserProfile} from "@/hooks/useGetUserProfile";

interface GoFrensNftCardProp {
    ownership: ExtendedNftOwnership;
    className?: string;
    isSmallSize?: boolean;
    onClick?: () => void;
    showTooltip?: boolean;
}

function GoFrensNftCard(props: GoFrensNftCardProp): ReactElement {
    const [
        _loading,
        nftInformation,
    ] = useNftInformationFromUri(props.ownership.tokenUri);
    const [
        showModal,
        setShowModal,
    ] = useState<boolean>(false);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: userProfileData} = useGetUserProfile(fullToken);

    function handleOnClick(): void {
        if (props.onClick) {
            props.onClick();
            return;
        }
        setShowModal((_showModal) => !_showModal);
    }

    return (
        <>
            <NftOwnershipCardModal
                isOpen={showModal}
                onClose={handleOnClick}
                ownership={props.ownership}
                nftInformation={nftInformation}
            />
            <div
                key={props.ownership.id}
                className={classNames("gofrens-nft-card", props.className, {
                    "my-community-nft": props.ownership.ofCurrentCommunity && props.className === "my-nfts",
                    "my-profile-pic": props.className === "pfp-option" && userProfileData?.user.nftProfilePicture === props.ownership.id,
                })}
                onClick={handleOnClick}
            >
                {props.showTooltip && props.ownership.isEligibleForCompetitions === false &&
                    <div className="gofrens-nft-card_tooltip">
                        <Tooltip
                            className="nft-card-tooltip"
                            outsideIcon={InfoCircle}
                            insideIcon={InfoCircle}
                            containerElementClassname="gofrens-my-nfts_own-nfts"
                        >
                            <p>This NFT is not eligible for community competitions</p>
                        </Tooltip>
                    </div>}

                <div className="gofrens-nft-card_image-container">
                    {nftInformation?.imageBase64 && nftInformation?.imageBase64.startsWith("data:video")
                        ? <video
                            className="gofrens-nft-card_image-container_video-wrapper"
                            loop
                            autoPlay
                            src={nftInformation.imageBase64}
                            playsInline={true}
                        />
                        : nftInformation?.imageResolveUrl || nftInformation?.imageBase64
                            ? <img
                                className="gofrens-nft-card_image-container_image-wrapper"
                                alt="GoFriends Card Thumbnail"
                                src={nftInformation?.imageBase64 || nftInformation?.imageResolveUrl}
                            />
                            : null}
                </div>
                <div className="gofrens-nft-card_content">
                    <h5>{`#${props.ownership?.nftId}`}</h5>
                    {!props.isSmallSize &&
                    <p className="gofrens-nft-card_content_collection">
                        {props.ownership.tracker.name}
                    </p>
                    }
                </div>
            </div>
        </>
    );

}

export default GoFrensNftCard;