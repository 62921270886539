import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UsersApi} from "@devour/client";
import getConfig from "@/utils/getConfig";
import {addError} from "@/redux/meta/metaActions";
import {IStore} from "@/redux/defaultStore";

// TODO: use this for goFriends once can deploy to mirror1
export default function useCreateRefreshNftOwnerships() {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const dispatch = useDispatch();

    const refreshNftOwnerships = useCallback(async (force: boolean) => {
        if (!fullToken) {
            return;
        }

        setIsRefreshing(true);
        try {
            const res = await new UsersApi(getConfig()).updateNftOwnershipForUserRaw({force: force ? "true" : "false"});
            if (res.raw.status === 200) {
                window.location.reload();
            }
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            setIsRefreshing(false);
        }
    }, [fullToken?.id]);

    return { isRefreshing, refreshNftOwnerships };
}