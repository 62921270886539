import {useQuery} from "@tanstack/react-query";
import {
    GetGuildProgressResponse,
    GuildsApi,
    Token,
} from "@devour/client";
import getConfig from "@/utils/getConfig";

async function fetchGuildProgress(guildId: string, freshCopy?: boolean): Promise<GetGuildProgressResponse> {
    return await new GuildsApi(getConfig()).getGuildProgress({
        id: guildId,
        freshCopy: freshCopy,
    });
}

function getGuildProgressQuery(guildId: string, fullToken?: Token, freshCopy?: boolean) {
    return {
        queryKey: [
            "get-guild-progress",
            guildId,
            fullToken?.id ?? "unauthenticated",
            freshCopy,
        ],
        queryFn: () => fetchGuildProgress(guildId, freshCopy),
        staleTime: 60000,
        enabled: Boolean(guildId),
    } as const;
}

export function useGetGuildProgress(guildId: string, fullToken?: Token, freshCopy?: boolean) {
    return useQuery(getGuildProgressQuery(guildId, fullToken, freshCopy));
}