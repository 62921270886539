import {ButtonHTMLAttributes, ChangeEvent, ReactElement, useEffect, useMemo, useState} from "react";
import {isDesktop, isTablet} from "react-device-detect";
import FrameButton from "@/components/buttons/FrameButton";
import {AiOutlineSearch} from "react-icons/ai";
import FrameOneSwitchInput from "@/components/inputs/FrameOneSwitchInput";
import {LuLayoutGrid, LuList} from "react-icons/lu";
import {Guild, OverwolfApi, OWGame} from "@devour/client";
import GuildCard from "@/pages/guilds/GuildCard";
import GoFrensNftGroupingsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensNftGroupingsSkeleton";
import GoFrensNoResultsGraphic from "@/components/goFrens/GoFrensNoResultsgraphic";
import Skeleton from "@/components/skeletons/Skeleton";
import getConfig from "@/utils/getConfig";
import GuildsExploreListViewItem from "@/pages/guilds/GuildsExploreListViewItem";

interface Props {
    guilds: Array<Guild>;
}

enum ViewStates {
    GRID,
    ROW,
}
export default function GuildsExplore(props: Props) {
    const [searchValue, setSearchValue] = useState<string>("");
    const [viewState, setViewState] = useState<ViewStates>(ViewStates.GRID);
    const [games, setGames] = useState<Array<OWGame>>([]);

    const queriedGuilds = useMemo(
        () =>
            props.guilds?.filter(guild => guild.name.toLowerCase().includes(searchValue.toLowerCase())) ?? [],
        [props.guilds, searchValue],
    );

    useEffect(() => {
        void fetchGames();
    }, []);

    async function fetchGames() {
        try {
            const gamesData = await new OverwolfApi(getConfig()).getOverwolfDualGames();
            setGames(gamesData);
        } catch (e) {}
    }

    function onSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    function renderGuildsGrid(): ReactElement {
        if (!props.guilds) {
            return <div className="guilds-explore_content_grid-view">
                <GoFrensNftGroupingsSkeleton noOfCards={isTablet ? 3 : 4}/>
            </div>;
        }

        if (queriedGuilds.length === 0) {
            return <GoFrensNoResultsGraphic message="Sorry, we couldn’t find anything." />;
        }

        return <div className="guilds-explore_content_grid-view">
            {queriedGuilds.map(guild => <GuildCard key={guild.id} guild={guild} />)}
        </div>;
    }

    function renderGuildsListView(): ReactElement {
        if (!props.guilds) {
            return <Skeleton rows={5} />;
        }

        if (queriedGuilds.length === 0) {
            return <GoFrensNoResultsGraphic message="Sorry, we couldn’t find anything." />;
        }

        return (
            <div className="gofrens-community-listview">
                <div className="gofrens-community-listview_header guilds-explore_content_list_row">
                    <div className="gofrens-community-listview_header_item guilds-explore_content_list_row_guild">
                        <span>Guild</span>
                    </div>
                    <div className="gofrens-community-listview_header_item guilds-explore_content_list_row_games">
                        <span>Games</span>
                    </div>
                    <div className="gofrens-community-listview_header_item right-align">
                        <span>DevourGO members</span>
                    </div>
                    <div className="gofrens-community-listview_header_item right-align">
                        <span>Rank</span>
                    </div>
                </div>

                <div className="gofrens-community-listview_body">
                    {queriedGuilds.map(guild => <GuildsExploreListViewItem
                        key={guild.id}
                        guild={guild}
                        games={games?.filter(game => guild.games.includes(game.id))}
                    />)}
                </div>
            </div>
        );
    }

    return (
        <div className="guilds-explore guilds-page_section">
            <h3 className="guilds-explore_header">Explore Game Guilds</h3>

            <div className="guilds-explore_search gofrens-other-communities_header_search-container">
                <div className="gofrens-other-communities_header_search-container_input">
                    <AiOutlineSearch/>
                    <input
                        value={searchValue}
                        onChange={onSearchChange}
                        type="search"
                        placeholder="Search a guild"/>
                </div>
                <FrameOneSwitchInput
                    name="layout-change"
                    onToggle={(val) => setViewState(val as ViewStates)}
                    value={viewState}
                    options={[
                        {value: ViewStates.GRID, render: <LuLayoutGrid/>},
                        {value: ViewStates.ROW, render: <LuList/>},
                    ]}
                />
            </div>

            <div className="guilds-explore_content">
                {viewState === ViewStates.GRID ? renderGuildsGrid() : renderGuildsListView()}
            </div>
        </div>
    );
}