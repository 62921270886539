import React, {ButtonHTMLAttributes, ReactElement} from "react";
import {Guild, GuildsApi} from "@devour/client";
import {useGetGuildProgress} from "@/hooks/useGetGuildProgress";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import getConfig from "@/utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import FrameButton from "@/components/buttons/FrameButton";
import classNames from "classnames";
import {DateTime} from "luxon";
import useWindowSize from "@/hooks/useWindowSize";
import useOverwolfInterop from "@/hooks/useOverwolfInterop";
import {MdCheck} from "react-icons/md";

interface Props {
    guild: Guild;
    isOnOverwolf?: boolean;
}

function GuildMintBadgeButton(props: Props): ReactElement {
    const dispatch = useDispatch();
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const { data: guildProgress, refetch: refetchGuildProgress } = useGetGuildProgress(props.guild?.id, fullToken);
    const windowSize = useWindowSize()[0];
    const isInGameView = windowSize < 400;
    const {isOnOverwolf} = useOverwolfInterop();

    const renderClaimStr = () => {
        if (guildProgress?.hasUserMintedBadge) {
            const completedDate = DateTime
                .fromMillis(guildProgress?.mintBadgeDate)
                .toLocaleString({month: "short", day: "numeric"});

            // Overwolf desktop view
            if (isOnOverwolf && !isInGameView) {
                return <>Claimed <MdCheck/></>;
            }

            return <>Claimed on {completedDate} <MdCheck/></>;
        }
        return "Claim";
    };

    async function mintGuildBadge(): Promise<void> {
        if (guildProgress?.hasUserMintedBadge) {
            return;
        }
        dispatch(incrementLoading());
        try {
            await new GuildsApi(getConfig()).mintGuildBadge({
                id: props.guild?.id,
            });
            await refetchGuildProgress();
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }

    }

    return (
        <div className="guild-mint-badge">
            <FrameButton
                <ButtonHTMLAttributes<HTMLButtonElement>>
                color={guildProgress?.hasUserMintedBadge ? undefined : "devourplay-gradient"}
                size="narrow"
                onClick={mintGuildBadge}
                className={classNames("guild-mint-badge-btn", {
                    "claimed": guildProgress?.hasUserMintedBadge || !guildProgress?.isGuildQualifierQuestsCompleted,
                })}
                forwardProps={{
                    disabled: !guildProgress?.isGuildQualifierQuestsCompleted,
                }}
            >
                {renderClaimStr()}
            </FrameButton>
        </div>
    );
}

export default GuildMintBadgeButton;
