import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import GuildsHeader from "@/pages/guilds/GuildsHeader";
import useGetGuildOwnerships from "@/hooks/useGetGuildOwnerships";
import DevourTopNav from "@/components/DevourTopNav";
import GuildsExplore from "@/pages/guilds/GuildsExplore";
import GuildsSearchResults from "@/pages/guilds/GuildsSearchResults";
import {useSearchParams} from "react-router-dom";
import classNames from "classnames";
import GuildBadges from "@/pages/guilds/GuildBadges";
import GuildBadgesViewAll from "@/pages/guilds/GuildBadgesViewAll";

export default function GuildsPage() {
    const [searchParams] = useSearchParams();

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const {data: guildOwnerships} = useGetGuildOwnerships(fullToken, true);

    if (searchParams.get("search")) {
        return <GuildsSearchResults />;
    } if (searchParams.get("view") === "badges") {
        return <GuildBadgesViewAll />;
    }

    return (
        <div className={classNames("guilds-page", {
            "guilds-page_default": !fullToken || guildOwnerships?.badges.length === 0,
        })}>
            <DevourTopNav text="Guilds"/>
            {/* header  */}
            <GuildsHeader />
            {guildOwnerships?.badges.length > 0 &&
                <>
                    {/* guild member => guild leaderboard */}
                    {/* <div className="gofrens_divider"/>*/}

                    {/* top leaderboard   */}

                    <div className="gofrens_divider"/>

                    {/* my badges    */}
                    <GuildBadges badges={guildOwnerships.badges}/>
                    <div className="gofrens_divider"/>
                </>
            }
            {/*  explore game guilds  */}
            <GuildsExplore guilds={guildOwnerships?.otherGuilds}/>
        </div>
    );
}