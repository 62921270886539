import {FiChevronLeft} from "react-icons/fi";
import {useNavigate, useSearchParams} from "react-router-dom";
import useGetGuildOwnerships from "@/hooks/useGetGuildOwnerships";
import {useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import GoFrensNftGroupingsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensNftGroupingsSkeleton";
import {isDesktop, isTablet} from "react-device-detect";
import GoFrensMyNftsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensMyNftsSkeleton";
import GuildCard from "@/pages/guilds/GuildCard";
import GoFrensNftCard from "@/components/goFrens/GoFrensNftCard";
import {useEffect} from "react";

export default function GuildsSearchResults() {
    const [searchParams] = useSearchParams();
    const searchQuery = searchParams.get("search")?.toLowerCase() || "";
    const navigate = useNavigate();

    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);

    const {data: guildOwnerships, isLoading: isGuildOwnershipsLoading} = useGetGuildOwnerships(fullToken, true);
    const filteredBadges = guildOwnerships?.badges.filter(badge => badge.tracker?.name?.toLowerCase().includes(searchQuery)) || [];
    const filteredGuilds = [
        ...guildOwnerships?.qualifiedGuilds?.filter(guild =>
            guild.name?.toLowerCase().includes(searchQuery)) || [],
        ...guildOwnerships?.otherGuilds?.filter(guild =>
            guild.name?.toLowerCase().includes(searchQuery)) || [],
    ];

    useEffect(() => {
        if (!fullToken) {
            navigate("/guilds");
        }
    }, [fullToken?.id]);

    function renderSkeleton() {
        return (
            <div className="gofrens-search_results">
                <div className="gofrens-search-container_results_communities">
                    <p className="gofrens-search_results_communities_header">Guilds</p>
                    <div className="gofrens-search_results_communities_body">
                        {<GoFrensNftGroupingsSkeleton noOfCards={isDesktop ? 4 : isTablet ? 3 : 2}/>}
                    </div>
                </div>

                <div className="gofrens-search_results_nfts">
                    <p className="gofrens-search_results_nfts_header">Badges</p>
                    <div className="gofrens-search_results_communities_body">
                        {<GoFrensMyNftsSkeleton noOfCards={isDesktop ? 4 : isTablet ? 3 : 2}/>}
                    </div>
                </div>
            </div>
        );
    }

    function renderNoResultsFound() {
        return (
            <>
                <div className="gofrens-search_no-results">
                    <div className="gofrens-search_no-results_content">
                        <img src={import.meta.env.VITE_CDN_URL + "/images/gofrens-question-mark.webp"} alt="question-mark"/>
                        <h3>Oops, Nothing Found!</h3>
                        <p className="gofrens-search_no-results_content_message">Sorry, we couldn't find
                            anything. Feel free to broaden your search or check other guilds below.</p>
                    </div>
                </div>
                <div className="gofrens-search_other-communities">
                    <h3 className="gofrens-search_other-communities_title">Explore Other Guilds</h3>
                    <div className="gofrens-search_other-communities_body">
                        {guildOwnerships.qualifiedGuilds.map(guild => <GuildCard guild={guild} key={guild.id}/>)}
                    </div>
                </div>
            </>
        );
    }

    function renderResults() {
        if (filteredGuilds.length === 0 && filteredBadges.length === 0) {
            return renderNoResultsFound();
        }

        return (
            <div className="gofrens-search_results">
                {filteredGuilds.length > 0 && <div className="gofrens-search_results_communities">
                    <p className="gofrens-search_results_communities_header">Guilds</p>
                    <div className="gofrens-search_results_communities_body">
                        {filteredGuilds.map(guild => <GuildCard guild={guild} key={guild.id}/>)}
                    </div>
                </div>}

                {filteredBadges.length > 0 && <div className="gofrens-search_results_nfts">
                    <p className="gofrens-search_results_nfts_header">Badges</p>
                    <div className="gofrens-search_results_communities_body">
                        {filteredBadges.map(badge => <GoFrensNftCard
                            key={badge.id}
                            ownership={badge}
                            className="gofrens-search-result"
                        />)}
                    </div>
                </div>}
            </div>
        );
    }

    return (
        <div className="gofrens-search">
            <div className="gofrens-search_back" onClick={() => navigate("/guilds")}>
                <FiChevronLeft className="view-all_back_icon"/>
                <p>Go Back</p>
            </div>
            <h3 className="gofrens-search_title">Search Result for "{searchParams.get("search") || ""}"</h3>
            {isGuildOwnershipsLoading && renderSkeleton()}
            {guildOwnerships && renderResults()}
        </div>
    );
}