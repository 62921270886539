import {Guild, OWGame} from "@devour/client";
import {useAsset} from "@/hooks/useAsset";

interface Props {
    guild: Guild;
    games: Array<OWGame>;
}

export default function GuildsExploreListViewItem(props: Props) {
    const {guild, games} = props;
    const {data: icon} = useAsset(props.guild.icon);

    return (
        <div className="gofrens-community-listview_body_row guilds-explore_content_list_row">
            <div className="gofrens-community-listview_body_row_item collection-column guilds-explore_content_list_row_guild">
                <div className="guilds-explore_content_list_img-container">
                    {icon && <img
                        className="gofrens-community-listview_body_row_item_image"
                        src={icon?.url}
                        alt="guild icon"
                    />}
                </div>
                <div className="collection-column_info">
                    <span>{guild.name}</span>
                </div>
            </div>
            <div className="gofrens-community-listview_body_row_item guilds-explore_content_list_row_games">
                {games?.map(game => <div>{game.name}</div>)}
            </div>
            <div className="gofrens-community-listview_body_row_item right-align">
                <span>{guild.memberCount}</span>
            </div>
            <div className="gofrens-community-listview_body_row_item right-align">
                <span className={!guild.rank && "placeholder-text"}>{guild.rank || "-"}</span>
            </div>
        </div>
    );
}
