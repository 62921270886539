import {ButtonHTMLAttributes, ReactElement, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Token, NftOwnership, UsersApi} from "@devour/client";
import {IStore} from "@/redux/defaultStore";
import FrameButton from "@/components/buttons/FrameButton";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import GoFrensNftCard from "@/components/goFrens/GoFrensNftCard";
import GoFrensMyNftsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensMyNftsSkeleton";
import {MdRefresh} from "react-icons/md";
import getConfig from "@/utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "@/redux/meta/metaActions";
import {useGetNftOwnerships} from "@/hooks/useGetNftOwnerships";
import DragScroll from "@/components/DragScroll";

export interface ExtendedNftOwnership extends NftOwnership {
    eligible?: boolean;
}
function GoFrensMyNfts(): ReactElement {
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const dispatch = useDispatch();
    const {data: nftOwnershipsData} = useGetNftOwnerships(fullToken as Token);

    const [
        isGoFrensLoading,
        setIsGoFrensLoading,
    ] = useState<boolean>(false);

    function createRefreshNftOwnerships(force: boolean): () => Promise<void> {
        return async () => {
            dispatch(incrementLoading());
            setIsGoFrensLoading(true);
            try {
                const res = await new UsersApi(getConfig()).updateNftOwnershipForUserRaw({force: force
                    ? "true"
                    : "false"});
                if (res.raw.status === 200) {
                    window.location.reload();
                }
            } catch (e) {
                dispatch(await addError(e));
            } finally {
                setIsGoFrensLoading(false);
                dispatch(decrementLoading());
            }
        };
    }

    return (
        <div className='gofrens-my-nfts'>
            <div className='gofrens-my-nfts_header'>
                <div className='gofrens-my-nfts_header_main'>
                    <div className='gofrens-my-nfts_header_main_top'>
                        <div className='gofrens-my-nfts_header_main_top_title'>
                            <h3>My NFTs</h3>
                        </div>
                        <div className='gofrens-my-nfts_header_main_top_number'>
                            <span>{nftOwnershipsData
                                ? nftOwnershipsData.nftOwnerships.length
                                : 0}</span>
                        </div>
                        <div
                            className='gofrens-my-nfts_header_main_top_btn'
                            onClick={async() => await createRefreshNftOwnerships(true)()}
                        >
                            <MdRefresh />
                            <span>{isGoFrensLoading
                                ? "Loading..."
                                : "Refresh"}</span>
                        </div>
                    </div>
                    <div className='gofrens-my-nfts_header_main_description'>All NFTs I own for GoFriends</div>
                </div>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="white-drop-shadow"
                    size={isMobile && !isTablet
                        ? "pill"
                        : "narrow"}
                    to="/gofriends-my-nfts"
                    className="gofrens-my-communities_description_button"
                >
                    View All
                </FrameButton>
            </div>
            {!nftOwnershipsData && <GoFrensMyNftsSkeleton noOfCards={isDesktop
                ? 6
                : !isTablet && isMobile
                    ? 2
                    : 4}/>}

            <DragScroll className='gofrens-my-nfts_own-nfts'>
                <>
                    {nftOwnershipsData && nftOwnershipsData.nftOwnerships.slice(0, 12).map((ownership) => {
                        return (
                            <GoFrensNftCard
                                key={ownership?.id}
                                ownership={ownership}
                                className="my-nfts"
                                showTooltip={true}
                            />
                        );
                    })}
                </>
            </DragScroll>
        </div>
    );

}

export default GoFrensMyNfts;
