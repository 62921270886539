import {ButtonHTMLAttributes, ChangeEvent, ReactElement, useEffect, useState} from "react";
import {
    EVMChain,
    NftGrouping,
    NftGroupingCategory,
    NFTGroupingsCategoriesApi,
    NftOwnershipInformation,
} from "@devour/client";
import GoFrensCommunityCard from "@/components/goFrens/GoFrensCommunityCard";
import { sortNftGroupingsByPriority } from "@/utils/sortNftGroupingsByPriority";
import GoFrensNftGroupingsSkeleton from "@/components/skeletons/GoFrensPage/GoFrensNftGroupingsSkeleton";
import { useSelector } from "react-redux";
import { IStore } from "@/redux/defaultStore";
import { AiOutlineSearch } from "react-icons/ai";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import GoFrensNoResultsGraphic from "@/components/goFrens/GoFrensNoResultsgraphic";
import FrameOneSelect from "../inputs/FrameOneSelect";
import { getChains } from "@/utils/parseChain";
import FrameButton from "../buttons/FrameButton";
import FrameOneSwitchInput from "../inputs/FrameOneSwitchInput";
import { LuLayoutGrid, LuList } from "react-icons/lu";
import GoFrensCommunityListView from "./GoFrensCommunityListView";
import Skeleton from "../skeletons/Skeleton";
import getOwnedNftData from "@/utils/getOwnedNftData";

interface Props {
    nftOwnershipResponse: NftOwnershipInformation;
    isNftOwnershipResponseLoading: boolean;
}

enum ViewStates {
    GRID,
    ROW,
}

function GoFrensOtherCommunities(props: Props): ReactElement {
    const [searchValue, setSearchValue] = useState<string>("");

    const fullToken = useSelector<IStore>((state) => state.authStore.fullToken);

    const [viewState, setViewState] = useState<ViewStates>(ViewStates.GRID);

    const [categories, setCategories] = useState<NftGroupingCategory[]>([]);

    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [selectedChain, setSelectedChain] = useState<EVMChain | null>(null);

    useEffect(() => {
        fetchNftGroupingCategories();
    }, []);

    function isNftOwned() {
        if (!fullToken) {
            return false;
        }
        if (props.isNftOwnershipResponseLoading) {
            return true;
        }
        if (!props.nftOwnershipResponse?.nftOwnerships?.length) {
            return false;
        }
        return !(
            props.nftOwnershipResponse?.nftOwnerships?.length === 1 && !props.nftOwnershipResponse?.nftOwnerships[0].length
        );
    }

    function onSearchChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearchValue(e.target.value);
    }

    async function fetchNftGroupingCategories() {
        const response = await new NFTGroupingsCategoriesApi().getNftGroupingCategories();
        setCategories(response.nftGroupingCategories);
    }

    function filterNftGrouping(grouping: NftGrouping): NftGrouping {
        let isValidSearch = true;
        let isValidCategory = true;
        let isValidChain = true;
        if (searchValue.length) {
            isValidSearch = grouping.name.toLowerCase().includes(searchValue.toLowerCase());
        }

        if (selectedCategory) {
            isValidCategory = grouping.categories?.includes(selectedCategory);
        }

        if (selectedChain) {
            isValidChain = grouping.chains?.includes(selectedChain);
        }

        return [isValidSearch, isValidCategory, isValidChain].includes(false) ? null : grouping;
    }

    function handleChainChange(e: ChangeEvent<HTMLSelectElement>) {
        setSelectedChain(e.target.value === "" ? null : (e.target.value as EVMChain));
    }

    function handleCategoryChange(e: ChangeEvent<HTMLSelectElement>) {
        setSelectedCategory(e.target.value === "" ? null : (e.target.value as EVMChain));
    }

    function handleClearFilters() {
        setSearchValue("");
        setSelectedCategory(null);
        setSelectedChain(null);
    }
    function renderNftGrouping(grouping: NftGrouping): ReactElement {
        const ownershipRes = props.nftOwnershipResponse;
        const groupedInfo = getOwnedNftData(grouping, ownershipRes.nftTrackers, ownershipRes.nftOwnerships);
        return (
            <GoFrensCommunityCard
                key={grouping.id}
                grouping={grouping}
                trackers={groupedInfo.groupingTrackers}
                owned={groupedInfo.ownedCount}
            />
        );
    }

    function showNftGroupings(): Array<ReactElement> {
        return props.nftOwnershipResponse?.nftGroupings
            ?.filter(filterNftGrouping)
            .sort(sortNftGroupingsByPriority)
            .map(renderNftGrouping);
    }

    function renderGroupingsGrid() {
        return (
            <div className="gofrens-other-communities_card-container">
                {props.nftOwnershipResponse
                    ? props.nftOwnershipResponse?.nftGroupings?.filter(filterNftGrouping).length
                        ? showNftGroupings()
                        : <GoFrensNoResultsGraphic message={renderPlaceholderMessage()} />

                    : <GoFrensNftGroupingsSkeleton noOfCards={isDesktop ? 3 : 4} />
                }
            </div>
        );
    }

    function renderGroupingsListView() {
        const groupings = props.nftOwnershipResponse?.nftGroupings
            ?.filter(filterNftGrouping)
            ?.sort(sortNftGroupingsByPriority);
        if (!props.nftOwnershipResponse) {
            return <Skeleton rows={5}></Skeleton>;
        }
        return (
            <>
                {groupings?.length === 0
                    ? <GoFrensNoResultsGraphic message={renderPlaceholderMessage()} />
                    : <GoFrensCommunityListView
                        ownerships={props.nftOwnershipResponse.nftOwnerships}
                        categories={categories}
                        groupings={groupings}
                        trackers={props.nftOwnershipResponse?.nftTrackers}
                    />
                }
            </>
        );
    }

    const renderPlaceholderMessage = () => {
        const chainName = getChains().find((chain) => chain.chain === selectedChain)?.name;
        const categoryName = categories.find((category) => category.id === selectedCategory)?.name;
        let firstSection = <>Sorry, we couldn’t find anything.</>;
        if (chainName && categoryName) {
            firstSection =
        <>
          Sorry, we couldn’t find anything for the <span className="highlight-filter">{chainName}</span> chain with{" "}
            <span className="highlight-filter">{categoryName}</span> category.
        </>;
        } else if (chainName) {
            firstSection =
        <>
          Sorry, we couldn’t find anything for the <span className="highlight-filter">{chainName}</span> chain.
        </>;
        } else if (selectedCategory) {
            firstSection =
        <>
          Sorry, we couldn’t find anything for the <span className="highlight-filter">{categoryName}</span> category.
        </>;
        }
        return <span>{firstSection} Please try a different chain or category.</span>;
    };

    return (
        <div className="gofrens-other-communities">
            <div className="gofrens-other-communities_header">
                <div className="gofrens-other-communities_header_title-container">
                    <h3>{`${isDesktop || isTablet ? "Explore Other Communities" : "Other Communities"}`}</h3>
                    <div className="gofrens-other-communities_header_title-container_right">
                        <FrameButton<ButtonHTMLAttributes<HTMLButtonElement>>
                            color="white-drop-shadow"
                            size={isMobile && !isTablet ? "pill" : "narrow"}
                            onClick={handleClearFilters}
                        >
              View All
                        </FrameButton>
                    </div>
                </div>
                <div className="gofrens-other-communities_header_search-container">
                    <div className="gofrens-other-communities_header_search-container_input">
                        <AiOutlineSearch />
                        <input value={searchValue} onChange={onSearchChange} type="search" placeholder="Search a community" />
                    </div>
                    <FrameOneSwitchInput
                        name="layout-change"
                        onToggle={(val) => setViewState(val as ViewStates)}
                        value={viewState}
                        options={[
                            { value: ViewStates.GRID,
                                render: <LuLayoutGrid /> },
                            { value: ViewStates.ROW,
                                render: <LuList /> },
                        ]}
                    />
                </div>

                <div className="gofrens-other-communities_header_filters-container">
                    <FrameOneSelect
                        onChange={handleCategoryChange}
                        data-has-selection={!!selectedCategory}
                        className="gofrens-other-communities_header_filters-container_categories"
                    >
                        <option value="">All categories</option>
                        {categories.map((category) =>
                            <option selected={category.id == selectedCategory} value={category.id} key={category.id}>
                                {category.name}
                            </option>)}
                    </FrameOneSelect>
                    <FrameOneSelect
                        onChange={handleChainChange}
                        data-has-selection={!!selectedChain}
                        className="gofrens-other-communities_header_filters-container_chains"
                    >
                        <option value="">All chains</option>
                        {getChains().map((chain) =>
                            <option selected={chain.chain == selectedChain} value={chain.chain} key={chain.chain}>
                                {chain.name}
                            </option>)}
                    </FrameOneSelect>
                </div>
            </div>
            {viewState === ViewStates.GRID && renderGroupingsGrid()}
            {viewState === ViewStates.ROW && renderGroupingsListView()}
        </div>
    );
}

export default GoFrensOtherCommunities;
