import {NftOwnership} from "@devour/client";
import useCreateRefreshNftOwnerships from "@/hooks/useCreateRefreshNftOwnerships";
import {MdRefresh} from "react-icons/md";
import FrameButton from "@/components/buttons/FrameButton";
import {ButtonHTMLAttributes} from "react";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import GoFrensNftCard from "@/components/goFrens/GoFrensNftCard";

interface Props {
    badges: Array<NftOwnership>;
}
export default function GuildBadges(props: Props) {
    const {badges} = props;
    const {isRefreshing, refreshNftOwnerships} = useCreateRefreshNftOwnerships();

    return (
        <div className="guilds-badges guilds-page_section gofrens-my-nfts">
            <div className="gofrens-my-nfts_header">
                <div className="gofrens-my-nfts_header_main">
                    <div className="gofrens-my-nfts_header_main_top">
                        <div className='gofrens-my-nfts_header_main_top_title'>
                            <h3>{(isDesktop || isTablet) && "My"} Badges</h3>
                        </div>
                        <div className="gofrens-my-nfts_header_main_top_number">
                            <span>{badges?.length || 0}</span>
                        </div>
                        <button
                            className="reset-button gofrens-my-nfts_header_main_top_btn"
                            onClick={async () => await refreshNftOwnerships(true)}
                            disabled={isRefreshing}
                        >
                            <MdRefresh/>
                            {(isDesktop || isTablet) && <span>{isRefreshing ? "Loading..." : "Refresh"}</span>}
                        </button>
                    </div>
                    <div className="gofrens-my-nfts_header_main_description">Proof of Play Badges I own</div>
                </div>
                <FrameButton
                    <ButtonHTMLAttributes<HTMLButtonElement>>
                    color="white-drop-shadow"
                    size={isMobile && !isTablet ? "pill" : "narrow"}
                    to="/guilds?view=badges"
                    className="gofrens-my-communities_description_button"
                >
                    View All
                </FrameButton>
            </div>

            <div className='gofrens-my-nfts_own-nfts'>
                {badges.slice(0, 6).map(ownership =>
                    <GoFrensNftCard
                        key={ownership?.id}
                        ownership={ownership}
                    />)}
            </div>
        </div>
    );

}