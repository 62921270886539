/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiError,
    ApiErrorFromJSON,
    ApiErrorToJSON,
    GetGuildOwnershipResponse,
    GetGuildOwnershipResponseFromJSON,
    GetGuildOwnershipResponseToJSON,
    GetGuildProgressResponse,
    GetGuildProgressResponseFromJSON,
    GetGuildProgressResponseToJSON,
    GetGuildsResponse,
    GetGuildsResponseFromJSON,
    GetGuildsResponseToJSON,
    GetGuildsSort,
    GetGuildsSortFromJSON,
    GetGuildsSortToJSON,
    GetTopGuildsLeaderboardResponse,
    GetTopGuildsLeaderboardResponseFromJSON,
    GetTopGuildsLeaderboardResponseToJSON,
    NftPurchase,
    NftPurchaseFromJSON,
    NftPurchaseToJSON,
    SortOrder,
    SortOrderFromJSON,
    SortOrderToJSON,
} from '../models';

export interface ClaimQuestRequest {
    id: string;
}

export interface GetGuildProgressRequest {
    id: string;
    freshCopy?: boolean;
}

export interface GetGuildsRequest {
    gameIds?: Array<string>;
    search?: string;
    sort?: GetGuildsSort;
    order?: SortOrder;
    limit?: number;
    offset?: number;
    numberOfAvailableOffsets?: number;
}

export interface GetTopGuildsLeaderboardRequest {
    leaderboardTimeParam: string;
}

export interface JoinGuildRequest {
    id: string;
}

export interface MintGuildBadgeRequest {
    id: string;
}

/**
 * GuildsApi - interface
 * @export
 * @interface GuildsApiInterface
 */
export interface GuildsApiInterface {
    /**
     * Should allow a user to claim a quest reward.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    claimQuestRaw(requestParameters: ClaimQuestRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Should allow a user to claim a quest reward.
     */
    claimQuest(requestParameters: ClaimQuestRequest): Promise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getGuildOwnershipsRaw(): Promise<runtime.ApiResponse<GetGuildOwnershipResponse>>;

    /**
     */
    getGuildOwnerships(): Promise<GetGuildOwnershipResponse>;

    /**
     * Should get the current user\'s progress in a specific guild.
     * @param {string} id 
     * @param {boolean} [freshCopy] If true, bypasses cache and fetches fresh data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getGuildProgressRaw(requestParameters: GetGuildProgressRequest): Promise<runtime.ApiResponse<GetGuildProgressResponse>>;

    /**
     * Should get the current user\'s progress in a specific guild.
     */
    getGuildProgress(requestParameters: GetGuildProgressRequest): Promise<GetGuildProgressResponse>;

    /**
     * Should get a list of guilds.
     * @param {Array<string>} [gameIds] 
     * @param {string} [search] 
     * @param {GetGuildsSort} [sort] 
     * @param {SortOrder} [order] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {number} [numberOfAvailableOffsets] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getGuildsRaw(requestParameters: GetGuildsRequest): Promise<runtime.ApiResponse<GetGuildsResponse>>;

    /**
     * Should get a list of guilds.
     */
    getGuilds(requestParameters: GetGuildsRequest): Promise<GetGuildsResponse>;

    /**
     * Gets top guild rankings, sorted by total points or monthly points
     * @param {string} leaderboardTimeParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    getTopGuildsLeaderboardRaw(requestParameters: GetTopGuildsLeaderboardRequest): Promise<runtime.ApiResponse<GetTopGuildsLeaderboardResponse>>;

    /**
     * Gets top guild rankings, sorted by total points or monthly points
     */
    getTopGuildsLeaderboard(requestParameters: GetTopGuildsLeaderboardRequest): Promise<GetTopGuildsLeaderboardResponse>;

    /**
     * Should allow a user to join a guild.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    joinGuildRaw(requestParameters: JoinGuildRequest): Promise<runtime.ApiResponse<void>>;

    /**
     * Should allow a user to join a guild.
     */
    joinGuild(requestParameters: JoinGuildRequest): Promise<void>;

    /**
     * Should allow a user to leave a guild.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    leaveGuildRaw(): Promise<runtime.ApiResponse<void>>;

    /**
     * Should allow a user to leave a guild.
     */
    leaveGuild(): Promise<void>;

    /**
     * Should mint the guild proof of play badge to the user\'s wallet.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuildsApiInterface
     */
    mintGuildBadgeRaw(requestParameters: MintGuildBadgeRequest): Promise<runtime.ApiResponse<NftPurchase>>;

    /**
     * Should mint the guild proof of play badge to the user\'s wallet.
     */
    mintGuildBadge(requestParameters: MintGuildBadgeRequest): Promise<NftPurchase>;

}

/**
 * no description
 */
export class GuildsApi extends runtime.BaseAPI implements GuildsApiInterface {

    /**
     * Should allow a user to claim a quest reward.
     */
    async claimQuestRaw(requestParameters: ClaimQuestRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling claimQuest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/{id}/claim-quest`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Should allow a user to claim a quest reward.
     */
    async claimQuest(requestParameters: ClaimQuestRequest): Promise<void> {
        await this.claimQuestRaw(requestParameters);
    }

    /**
     */
    async getGuildOwnershipsRaw(): Promise<runtime.ApiResponse<GetGuildOwnershipResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Optional", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/ownerships`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGuildOwnershipResponseFromJSON(jsonValue));
    }

    /**
     */
    async getGuildOwnerships(): Promise<GetGuildOwnershipResponse> {
        const response = await this.getGuildOwnershipsRaw();
        return await response.value();
    }

    /**
     * Should get the current user\'s progress in a specific guild.
     */
    async getGuildProgressRaw(requestParameters: GetGuildProgressRequest): Promise<runtime.ApiResponse<GetGuildProgressResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getGuildProgress.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.freshCopy !== undefined) {
            queryParameters['freshCopy'] = requestParameters.freshCopy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Optional", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/{id}/progress`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGuildProgressResponseFromJSON(jsonValue));
    }

    /**
     * Should get the current user\'s progress in a specific guild.
     */
    async getGuildProgress(requestParameters: GetGuildProgressRequest): Promise<GetGuildProgressResponse> {
        const response = await this.getGuildProgressRaw(requestParameters);
        return await response.value();
    }

    /**
     * Should get a list of guilds.
     */
    async getGuildsRaw(requestParameters: GetGuildsRequest): Promise<runtime.ApiResponse<GetGuildsResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.gameIds) {
            queryParameters['gameIds'] = requestParameters.gameIds;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.numberOfAvailableOffsets !== undefined) {
            queryParameters['numberOfAvailableOffsets'] = requestParameters.numberOfAvailableOffsets;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/guilds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetGuildsResponseFromJSON(jsonValue));
    }

    /**
     * Should get a list of guilds.
     */
    async getGuilds(requestParameters: GetGuildsRequest): Promise<GetGuildsResponse> {
        const response = await this.getGuildsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets top guild rankings, sorted by total points or monthly points
     */
    async getTopGuildsLeaderboardRaw(requestParameters: GetTopGuildsLeaderboardRequest): Promise<runtime.ApiResponse<GetTopGuildsLeaderboardResponse>> {
        if (requestParameters.leaderboardTimeParam === null || requestParameters.leaderboardTimeParam === undefined) {
            throw new runtime.RequiredError('leaderboardTimeParam','Required parameter requestParameters.leaderboardTimeParam was null or undefined when calling getTopGuildsLeaderboard.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/top-guilds/{leaderboardTimeParam}`.replace(`{${"leaderboardTimeParam"}}`, encodeURIComponent(String(requestParameters.leaderboardTimeParam))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTopGuildsLeaderboardResponseFromJSON(jsonValue));
    }

    /**
     * Gets top guild rankings, sorted by total points or monthly points
     */
    async getTopGuildsLeaderboard(requestParameters: GetTopGuildsLeaderboardRequest): Promise<GetTopGuildsLeaderboardResponse> {
        const response = await this.getTopGuildsLeaderboardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Should allow a user to join a guild.
     */
    async joinGuildRaw(requestParameters: JoinGuildRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling joinGuild.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/{id}/join`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Should allow a user to join a guild.
     */
    async joinGuild(requestParameters: JoinGuildRequest): Promise<void> {
        await this.joinGuildRaw(requestParameters);
    }

    /**
     * Should allow a user to leave a guild.
     */
    async leaveGuildRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/leave`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Should allow a user to leave a guild.
     */
    async leaveGuild(): Promise<void> {
        await this.leaveGuildRaw();
    }

    /**
     * Should mint the guild proof of play badge to the user\'s wallet.
     */
    async mintGuildBadgeRaw(requestParameters: MintGuildBadgeRequest): Promise<runtime.ApiResponse<NftPurchase>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mintGuildBadge.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/guilds/{id}/mint`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NftPurchaseFromJSON(jsonValue));
    }

    /**
     * Should mint the guild proof of play badge to the user\'s wallet.
     */
    async mintGuildBadge(requestParameters: MintGuildBadgeRequest): Promise<NftPurchase> {
        const response = await this.mintGuildBadgeRaw(requestParameters);
        return await response.value();
    }

}
