import {Guild} from "@devour/client";
import classNames from "classnames";
import {useAsset} from "@/hooks/useAsset";
import {ReactComponent as Ellipse} from "../../svgs/ellipse15.svg";


interface Props {
    guild: Guild;
    className?: string;
}
export default function GuildCard(props: Props) {
    const {data: hero} = useAsset(props.guild.hero);
    const {data: icon} = useAsset(props.guild.icon);

    return (
        <div className={classNames("gofrens-community-card", props.className)}>
            <div className="gofrens-community-card_graphics">
                <div className={classNames(
                    "gofrens-community-card_graphics_hero",
                    {"no-hero": !hero},
                )}>
                    {hero &&
                        <img
                            src={hero.url}
                            alt={`${props.guild.name} hero`}
                            className="gofrens-community-card_graphics_hero_img"
                        />
                    }
                </div>
                <div className="gofrens-community-card_graphics_icon">
                    {icon &&
                        <img
                            src={icon.url}
                            alt={`${props.guild.name} icon`}
                        />
                    }
                </div>
            </div>

            <div className="gofrens-community-card_body">
                <div className="gofrens-community-card_body_description">
                    <p className="gofrens-community-card_body_description_name">{props.guild.name}</p>
                    <div className="gofrens-community-card_body_description_community-info">
                        <div className="gofrens-community-card_body_description_community-info_others">
                            <span>{props.guild.memberCount} members</span>
                            <span><Ellipse/></span>
                            <span>
                                {props.guild.rank
                                    ? `rank ${props.guild.rank}`
                                    : "Unranked"}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}